define("isolocity/helpers/relabeled-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    constants: Ember.inject.service('constants'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    compute: function compute(params, hash) {
      var intl = this.get('intl');
      var slug = params[0];
      var objectName = params[1];
      var customName = !Ember.isBlank(params[2]) ? params[2] : '';
      var cachedModules = Ember.getOwner(this).lookup('controller:application').model;

      if (!cachedModules) {
        cachedModules = this.get('store').peekAll('module');
      }

      var moduleDetails = cachedModules.filterBy('slug', slug).get('firstObject');
      var originalName = moduleDetails.original_name;
      var name = moduleDetails.name;

      if (!Ember.isBlank(customName)) {
        return name == originalName ? intl.t("modules.".concat(customName, ".").concat(slug)) : name;
      }

      return name == originalName ? intl.t("modules.".concat(objectName, ".").concat(slug)) : name;
    }
  });

  _exports.default = _default;
});