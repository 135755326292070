define("isolocity/helpers/calculate-since-initiated-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var startDate = params[0]; // First parameter is the start date
      // Ensure startDate is converted to a valid JavaScript Date object

      var start = startDate._isAMomentObject ? startDate.toDate() : new Date(startDate);
      var end = new Date(); // Current date

      var diffTime = Math.abs(end - start);
      var diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Use Math.floor instead of Math.ceil

      return diffDays;
    }
  });

  _exports.default = _default;
});