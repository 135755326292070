define("isolocity/pods/ncrs/new/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/non-conformance-mixin", "isolocity/mixins/ncr-part-a-feature-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _ownersAndSubscribersMixin, _nonConformanceMixin, _ncrPartAFeatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _ownersAndSubscribersMixin.default, _nonConformanceMixin.default, _ncrPartAFeatureMixin.default, {
    intl: Ember.inject.service(),
    id_prefix: 'new_',
    requiredFields: ['ncr_number', 'source'],
    defaultRequiredFieldsTranslation: {
      'source': 'Source',
      'ncr_number': 'Report #',
      'process_effect': 'Effect on Other Processes',
      'root_cause_assessment': 'Root Cause Assessment'
    },
    isNew: true,
    isLoading: false,
    isFormLocked: false,
    moduleSlug: 'ncrs',
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners'))) {
            fm.danger(intl.t('At least one owner is required'));
            return;
          }

          var model = this.get('model');
          this.set('isLoading', true);

          if (this.get('holdStatus.id')) {
            model.set('hold_status', this.get('holdStatus.id'));
          }

          if (this.get('ncrType.id')) {
            model.set('ncr_type', this.get('ncrType.id'));
          }

          if (this.get('phaseType.id')) {
            model.set('phase_type', this.get('phaseType.id'));
          }

          if (this.get('standardStatus.id')) {
            model.set('standard_status', this.get('standardStatus.id'));
          }

          model.save().then(function (ncr) {
            if (_this.get('holdStatus.id')) {
              model.set('hold_status', _this.get('holdStatus.id'));
            }

            if (_this.get('ncrType.id')) {
              model.set('ncr_type', _this.get('ncrType.id'));
            }

            if (_this.get('standardStatus.id')) {
              model.set('standard_status', _this.get('standardStatus.id'));
            }

            ncr.get('implementation_action_items').forEach(function (item) {
              item.set('entity_id', ncr.get('id'));
              item.save();
            });

            _this.send('saveAttachments', ncr);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.errors);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(ncr) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        fm.success(intl.t('A new report has been created successfully'));
        this.get('history').setRoute('ncrs');
        this.transitionToRoute('ncrs.edit', ncr);
      }
    }
  });

  _exports.default = _default;
});