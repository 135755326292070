define("isolocity/mixins/logout-handler-mixin", ["exports", "isolocity/mixins/freshchat-mixin"], function (_exports, _freshchatMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_freshchatMixin.default, {
    router: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    isAuthenticated: Ember.computed(function () {
      var auth = this.get('authManager');
      return auth.get('isAuthenticated');
    }),
    allowNavigationBar: Ember.computed('isAuthenticated', 'router.currentRouteName', function () {
      var isAuthenticated = this.get('isAuthenticated');
      var currentRoute = this.get('router.currentRouteName');
      var notAllowedRoutes = ['azure.callback']; // Add your non-allowed routes here for navigation bar
      // If  authenticated or not in "non-allowed" routes, return true

      return isAuthenticated && !notAllowedRoutes.includes(currentRoute);
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        var authManager = this.get('authManager');
        authManager.invalidate();
        authManager.set('session.currentUser', undefined);
        this.clearFreshchat();
        localStorage.removeItem('user_language');
        localStorage.removeItem("_tokenExpiredTime");
      }
    }
  });

  _exports.default = _default;
});