define("isolocity/pods/ncrs/edit/controller", ["exports", "isolocity/mixins/file-download-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/non-conformance-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/activity-log-mixin", "isolocity/mixins/ncr-part-a-feature-mixin"], function (_exports, _fileDownloadMixin, _requiredFieldsMixin, _historyHandlerMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _uploadFilesMixin, _pdfGenerationMixin, _ownersAndSubscribersMixin, _nonConformanceMixin, _ajaxRequestMixin, _eSignatureMixin, _activityLogMixin, _ncrPartAFeatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileDownloadMixin.default, _activityLogMixin.default, _requiredFieldsMixin.default, _historyHandlerMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _uploadFilesMixin.default, _pdfGenerationMixin.default, _ownersAndSubscribersMixin.default, _nonConformanceMixin.default, _ajaxRequestMixin.default, _ncrPartAFeatureMixin.default, {
    sideMenu: Ember.inject.service(),
    permissions: Ember.inject.service('user-permissions'),
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    id_prefix: 'edit_',
    requiredFields: Ember.computed(function () {
      var fields = ['ncr_number', 'source'];
      var moduleFields = this.get('modules').getModuleSetting('ncrs', 'fields', false);

      if (moduleFields && moduleFields['required']) {
        for (var key in moduleFields['required']) {
          if (moduleFields['required'].hasOwnProperty(key) && moduleFields['required'][key] == true) {
            fields.push(key);
          }
        }
      }

      return fields;
    }),
    PdfTitle: Ember.computed('model.ncr_number', function () {
      return 'Non-Conformance Report - NCR# ' + this.get('model.ncr_number');
    }),
    isEdit: true,
    isSaving: false,
    isReopening: false,
    isCreatingReport: false,
    canReopenNcr: false,
    hasDispositionChanged: false,
    noteEntityType: 'ncr',
    actionLink: 'ncrs.edit',
    actionEntityType: 'ncr',
    moduleSlug: 'ncrs',
    defaultRequiredFieldsTranslation: {
      'source': 'Source',
      'ncr_number': 'Report #',
      'process_effect': 'Effect on Other Processes',
      'root_cause_assessment': 'Root Cause Assessment'
    },
    actionTitleOptions: ['Complete Form Part A', 'Complete Form Part B', 'Close Out Report', 'Escalate to Corrective Action'],
    escalatedModuleName: Ember.computed('modules', function () {
      var causerType = this.get('model.causer_type');

      if (causerType == 'deviation') {
        var deviationModule = this.get('modules').getModuleBySlug('deviations');
        return deviationModule.name;
      } else if (causerType == 'complaint') {
        var complaintModule = this.get('modules').getModuleBySlug('complaints');
        return complaintModule.name;
      } else if (causerType == 'oosform') {
        var oosFormModule = this.get('modules').getModuleBySlug('oosforms');
        return oosFormModule.name;
      }
    }),
    isFormLocked: Ember.computed('isOwnedByCurrentUser', 'model.isClosed', function () {
      if (this.get('model.isClosed') || !this.get('permissions').hasRoleAtLeast('production')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isImplementationPlanLocked: Ember.computed('isOwnedByCurrentUser', 'model.is_closed', function () {
      if (this.get('model.isClosed') || !this.get('permissions').hasRoleAtLeast('production')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isDispositionLocked: Ember.computed('model.disposition_at', 'isFormLocked', function () {
      return this.get('isFormLocked') || !Ember.isBlank(this.get('model.disposition_at'));
    }),
    onDispositionChange: Ember.observer('model.disposition_at', 'isDispositionLocked', function () {
      var dispositionAt = this.get('model.disposition_at');

      if (dispositionAt) {
        this.set('isDispositionLocked', true);
      } else {
        this.set('isDispositionLocked', false);
      }
    }),
    canUpgradeNcrsToCars: Ember.computed('isOwnedByCurrentUser', 'model.car.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.car.id'));
    }),
    canCreateDeviation: Ember.computed('isOwnedByCurrentUser', 'model.car.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return (this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser')) && Ember.isBlank(this.get('model.deviation.id'));
    }),
    canOpenAndCloseNcrs: Ember.computed('isOwnedByCurrentUser', function () {
      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    canDeleteNcr: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    isFiveWhyAnalysis: Ember.computed('model.id', 'model.fishbone_5_why.root_cause_why_1', 'model.fishbone_5_why.root_cause_why_2', 'model.fishbone_5_why.root_cause_why_3', 'model.fishbone_5_why.root_cause_why_4', 'model.fishbone_5_why.root_cause_fishbone_5_why', function () {
      return !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_1')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_2')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_3')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_why_4')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_5_why'));
    }),
    isFishboneAnalysis: Ember.computed('model.id', 'model.fishbone_5_why.root_cause_fishbone_measurement', 'model.fishbone_5_why.root_cause_fishbone_material', 'model.fishbone_5_why.root_cause_fishbone_machine', 'model.fishbone_5_why.root_cause_fishbone_mother_nature', 'model.fishbone_5_why.root_cause_fishbone_man_power', 'model.fishbone_5_why.root_cause_fishbone_method', function () {
      return !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_measurement')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_material')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_machine')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_mother_nature')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_man_power')) || !Ember.isBlank(this.get('model.fishbone_5_why.root_cause_fishbone_method'));
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('ncrs', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save(type) {
        var _this = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (Ember.isBlank(this.get('model.owners')) && type != 'reopen') {
          fm.danger(intl.t('At least one owner is required'));
          return;
        }

        var model = this.get('model');

        if (this.get('holdStatus.id')) {
          model.set('hold_status', this.get('holdStatus.id'));
        }

        if (this.get('phaseType.id')) {
          model.set('phase_type', this.get('phaseType.id'));
        }

        if (this.get('holdStatus.id')) {
          model.set('hold_status', this.get('holdStatus.id'));
        }

        if (this.get('standardStatus.id')) {
          model.set('standard_status', this.get('standardStatus.id'));
        }

        if (type === 'reopen') {
          model.set('closed_at', null);
        }

        model.set('esignature_reason', this.get('eSignatureReason'));

        if (type === 'close') {
          this.notifyPropertyChange('requiredFields');

          if (this.get('isValid')) {
            model.set('closed_at', new moment());
          } else {
            this.send('showErrors');
            return;
          }
        }

        this.set('isSaving', true);
        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (ncr) {
          _this.set('hasDispositionChanged', false);

          _this.send('saveBatchRecords', _this.get('eSignaturePassword'), _this.get('eSignatureReason'));

          if (type === 'reopen') {
            _this.set('isSaving', false);
          } else {
            _this.send('saveAttachments', ncr, false, _this.get('eSignaturePassword'), _this.get('eSignatureReason'));
          }
        }, function (error) {
          if (type === 'close') {
            model.set('closed_at', null);
          }

          _this.set('isSaving', false);

          fm.danger(error.errors);
        });
      },
      reopen: function reopen() {
        var _this2 = this;

        var authHeaders = this.get('authHeaders');
        var model = this.get('model');
        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.set('isReopening', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ncr/' + model.get('id') + '/reopen'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          model.set('is_closed', false);
          model.set('date_closed', null);

          _this2.set('esignature_reason', _this2.get('eSignatureReason'));

          model.set('esignature_reason', _this2.get('eSignatureReason'));

          _this2.get('model').reload();

          fm.success(intl.t('The report has been re-opened'));
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this2.set('isReopening', false);
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(ncr) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('The changes to this report have been saved successfully'));
      },
      dispositionChanged: function dispositionChanged() {
        this.set('hasDispositionChanged', true);
      },
      approveDisposition: function approveDisposition() {
        var _this3 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');

        if (Ember.isBlank(this.get('model.disposition'))) {
          fm.danger("A disposition is required to continue");
          return;
        }

        var authHeaders = this.get('authHeaders');
        this.set('isAddingDispositionApproval', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ncr/' + this.get('model.id') + '/authorize'),
          method: 'POST',
          data: {
            disposition: this.get('model.disposition'),
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          _this3.get('model').reload().then(function () {
            fm.success(intl.t('Your approval has been added for this disposition'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to add your approval, please ensure your password is correct'));
        }).always(function () {
          _this3.set('hasDispositionChanged', false);

          _this3.set('isAddingDispositionApproval', false);

          _this3.set('eSignaturePassword', '');
        });
      },
      removeSignatureDisposition: function removeSignatureDisposition() {
        var _this4 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/ncr/' + this.get('model.id') + '/authorize'),
          method: 'DELETE',
          data: {
            disposition: this.get('model.disposition'),
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          }
        }).then(function () {
          if (_this4.get('isEsignatureRequiredForApprovals')) {
            _this4.get('model').reload().then(function () {
              fm.success(intl.t('Your approval has been removed for this disposition'));
            });
          } else {
            _this4.get('model').reload().then(function () {
              fm.success(intl.t('Your approval has been removed for this disposition'));
            });
          }
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        });
      },
      createReport: function createReport() {
        var ncr = this.get('model');
        this.set('isCreatingReport', true);
        var reportContent = this.getNcrReportContent(ncr);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this5 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var ncr = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/ncr/' + ncr.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this5.get('model.id');

            var model = 'ncr';

            var ncrNumber = _this5.get('model.ncr_number');

            var description = "NCR Report #".concat(ncrNumber, " has been downloaded");

            _this5.send('newActivityLog', id, model, description);

            _this5.send('downloadPdfFile', data, 'Report_' + _this5.get('model.ncr_number'));
          } else {
            _this5.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this5.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this5.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this6 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this6.send('downloadFile', file);
          }, timeout);
        });
      },
      createHoldTagReportNew: function createHoldTagReportNew() {
        var _this7 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var ncr = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/ncr/' + ncr.get('id') + '/hold-tag/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this7.get('model.id');

            var model = 'ncr';

            var ncrNumber = _this7.get('model.ncr_number');

            var description = "NCR Hold Tag Report #".concat(ncrNumber, " has been downloaded");

            _this7.send('newActivityLog', id, model, description);

            _this7.send('downloadPdfFile', data, 'Report_Hold_Tag' + _this7.get('model.ncr_number'));
          } else {
            _this7.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this7.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this7.set('isCreatingReport', false);
        });
      },
      createActionFillFormB: function createActionFillFormB() {
        var model = this.get('model');
        model.set('emptyAction', this.store.createRecord('action-item', {
          title: 'Complete Form Part B',
          priority: 'Medium',
          due_date: new moment().add(5, 'days')
        }));
        this.get("sideMenu").open('action-notes');
      },
      upgradeToCar: function upgradeToCar() {
        var model = this.get('model');
        var route = 'corrective-actions.new';
        this.get('history').setRoute('ncrs');
        var car = this.store.createRecord('car', {
          ncr_id: model,
          is_closed: false,
          date_opened: new Date(),
          initiator_name: model.get('initiator_user_id.name'),
          source: model.get('source'),
          source_supplier_id: model.get('source_supplier_id'),
          source_customer_id: model.get('source_customer_id'),
          error_code_id: model.get('error_code_id'),
          description: model.get('description'),
          immediate_action: model.get('immediate_action'),
          disposition: model.get('disposition'),
          disposition_authorized_by: model.get('disposition_user_id.name')
        });

        if (!Ember.isBlank(model.get('part_id'))) {
          car.set('type', 'Part');
          car.set('type_part_id', model.get('part_id'));
          car.set('type_part_count', model.get('quantity'));
        }

        this.get('persistence').add({
          route: route,
          model: car
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createDeviation: function createDeviation() {
        var route = 'deviations.new';
        this.get('history').setRoute('deviations');
        var deviation = this.store.createRecord('deviation', {
          causer_id: this.get('model.id'),
          causer_type: 'ncr'
        });
        this.get('persistence').add({
          route: route,
          model: deviation
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      createNcrs: function createNcrs() {
        var model = this.get('model');
        var route = 'ncrs.new';
        this.get('history').setRoute('ncrs');
        var ncr = this.store.createRecord('ncr', {
          causer_id: model.get('id'),
          causer_type: 'ncr'
        });
        this.get('persistence').add({
          route: route,
          model: ncr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      remove: function remove() {
        var _this8 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this8.get('model').destroyRecord().then(function () {
            _this8.transitionToRoute('ncrs');
          });

          _this8.set('deletePromise', null);
        }, function () {
          _this8.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      }
    }
  });

  _exports.default = _default;
});