define("isolocity/mixins/ncr-part-a-feature-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    modules: Ember.inject.service('modules'),
    holdStatusOptions: [],
    ncrTypeOptions: [],
    standardTypeOptions: [],
    phaseStatusOptions: [],
    enablePhaseTypeFeature: false,

    get module() {
      return this.get('modules').getModuleBySlug('ncrs');
    },

    init: function init() {
      var _this = this;

      this.holdStatusOptions = [{
        id: 'Hold',
        name: this.get('intl').t('ncrs.others.Hold')
      }, {
        id: 'Continued Production',
        name: this.get('intl').t('ncrs.others.Continued Production')
      }, {
        id: 'Cleared',
        name: this.get('intl').t('ncrs.others.Cleared')
      }, {
        id: 'Pending',
        name: this.get('intl').t('ncrs.others.Pending')
      }];
      this.phaseTypeOptions = [{
        id: '1 Phase',
        name: this.get('intl').t('ncrs.others.1 Phase')
      }, {
        id: '1ph-mini',
        name: this.get('intl').t('ncrs.others.1ph-mini')
      }, {
        id: '3 Phase',
        name: this.get('intl').t('ncrs.others.3 Phase')
      }];
      this.standardTypeOptions = [{
        id: 'Standard',
        name: this.get('intl').t('ncrs.others.Standard')
      }, {
        id: 'Non-standard',
        name: this.get('intl').t('ncrs.others.Non-standard')
      }];

      this._super.apply(this, arguments);

      $.ajax({
        headers: this.get('authHeaders'),
        url: this.getApiUrl('/public/check-feature-flags'),
        method: 'POST',
        data: {
          names: ["ncr_phase_type_feature"]
        },
        success: function success(data) {
          var isEnableFlagFeature = data.some(function (feature) {
            return feature.feature_name === "ncr_phase_type_feature" && feature.enabled;
          });

          _this.set('enablePhaseTypeFeature', isEnableFlagFeature);
        }
      });
    },
    holdStatus: Ember.computed('model.hold_status', function () {
      return this.get('model.hold_status') ? this.get('model.hold_status') : null;
    }),
    phaseType: Ember.computed('model.phase_type', function () {
      return this.get('model.phase_type') ? this.get('model.phase_type') : null;
    }),
    ncrType: Ember.computed('model.ncr_type', function () {
      return this.get('model.ncr_type') ? this.get('model.ncr_type') : null;
    }),
    standardStatus: Ember.computed('model.standard_status', function () {
      return this.get('model.standard_status') ? this.get('model.standard_status') : null;
    }),
    actions: {}
  });

  _exports.default = _default;
});