define("isolocity/pods/components/model-index/component", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service('router'),
    moduleService: Ember.inject.service('modules'),
    headerId: '',
    filter: '',
    singular: '',
    plural: '',
    disableHeaderButton: false,
    disableCreateButton: false,
    updateTablesorterEachRender: true,
    slugs: {},
    filterPlaceholder: Ember.computed('plural', function () {
      return this.get('intl').t('Search') + ' ' + this.get('plural');
    }),
    currentModule: Ember.computed(function () {
      return this.get('moduleService').getCurrentModule();
    }),
    filteredModel: Ember.computed('model.length', 'filterDropdownValue', 'filterChecked', function () {
      return this.getFilteredModel();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var intl = this.get('intl');
      this.slugs = {
        "plural": {
          "activities": "Activities",
          "action-items": "Action Items",
          "action-manager": "Action Manager",
          "activity-type": "Activity Types",
          "approval-matrices": "Approval Matrices",
          "asset-manager": "Asset Manager",
          "audit": "Audit Areas",
          "audit-manager": "Audits Manager",
          "audit-reports": "Audit Reports",
          "audit-report-templates": "Audit Templates",
          "audit-type": "Audit Types",
          "batch-manager": "Batch Manager",
          "calibration": "Calibrations",
          "car-source": "Car Sources",
          "car-type": "Corrective Action Types",
          "capa": "CAPAs",
          "capa-templates": "Templates",
          "ccr-classification": "Classifications",
          "ccr-source": "Sources",
          "ccr-type": "Change Control Types",
          "ccrs": "Change Control",
          "company-groups": "Team Groups",
          "companies": "Team Manager",
          "complaint-category": "Complaint Categories",
          "complaint-impact": "Complaint Impacts",
          "complaint-source": "Complaint Sources",
          "complaints": "Complaints",
          "costs": "Costs",
          "corrective-actions": "Corrective Actions",
          "department": "Departments",
          "deviation-source": "Deviation Types",
          "deviations": "Deviation Reports",
          "drawing": "Templates",
          "drawings": "Templates",
          "documents": "Document",
          "document-type": "Document Types",
          "employees": "Employees",
          "equipments": "Equipments",
          "equipment": "Equipment",
          "equipment-manager": "Equipment",
          "equipment-reports": "Equipment Records",
          "equipment-type": "Equipment Types",
          "error-code": "Error Codes",
          "groups": "Employee Groups",
          "human-resources": "Human Resources",
          "index": "Dashboards",
          "insights": "Insights",
          "inspections": "Inspections",
          "inspections-manager": "Inspections Manager",
          "inspections.activities": "Activity Inspections",
          "inspections.equipment": "Equipment Inspections",
          "inspections.receiving": "Receiving Inspections",
          "internal-audit": "Audits",
          "inventory": "Batches",
          "location": "Locations",
          "location-manager": "Location Manager",
          "location-type": "Location Types",
          "ncrs": "NCR",
          "ncr-type": "NCR Types",
          "oosforms": "Out Of Specifications",
          "orders": "Orders",
          "part.activity": "Activities Manager",
          "part-type": "Part Types",
          "parts": "Products",
          "planning": "Planning",
          "planning-reports": "Planning Reports",
          "product-manager": "Products",
          "products": "Products",
          "report-templates": "Report Templates",
          "reporting": "Reporting",
          "reports": "Quality Events",
          "receiving-inspections": "RI",
          "shipments": "Shipments",
          "shipping-receiving": "Shipping and Receiving",
          "suppliers": "Suppliers",
          "supply-chains": "Supply Chains",
          "task-manager": "Tasks Manager",
          "testing": "Tests",
          "training-programs": "Training Programs",
          "training-schedules": "Training Activities",
          "training-type": "Training Types",
          "users": "Users",
          "users-teams": "Users and Teams"
        },
        "singular": {
          "activities": "Activity",
          "action-items": "Action Item",
          "action-manager": "Action Manager",
          "activity-type": "Activity Types",
          "approval-matrices": "Approval Matrix",
          "asset-manager": "Asset Manager",
          "audit": "Audit Area",
          "audit-manager": "Audit Manager",
          "audit-reports": "Audit Report",
          "audit-report-templates": "Audit Template",
          "audit-type": "Audit Type",
          "batch-manager": "Batch Manager",
          "calibration": "Calibration",
          "car-source": "Car Source",
          "car-type": "Corrective Action Type",
          "capa": "CAPA",
          "capa-templates": "Templates",
          "ccr-classification": "Classification",
          "ccr-source": "Source",
          "ccr-type": "Change Control Type",
          "ccrs": "Change Control",
          "company-groups": "Team Group",
          "companies": "Team",
          "complaint-category": "Complaint Category",
          "complaint-impact": "Complaint Impact",
          "complaint-source": "Complaint Source",
          "complaints": "Complaint",
          "costs": "Cost",
          "corrective-actions": "Corrective Action Report",
          "customers": "Customer",
          "department": "Department",
          "deviation-source": "Deviation Type",
          "deviations": "Deviation Report",
          "drawing": "Template",
          "drawings": "Templates",
          "documents": "Document",
          "document-type": "Document Type",
          "employees": "Employee",
          "equipments": "Equipment",
          "equipment": "Equipment",
          "equipment-manager": "Equipment",
          "equipment-reports": "Equipment Records",
          "equipment-type": "Equipment Type",
          "error-code": "Error Code",
          "fillers": "Filler",
          "groups": "Employee Group",
          "human-resources": "Human Resource",
          "index": "Dashboard",
          "insights": "Insights",
          "inspections": "Inspection",
          "inspections-manager": "Inspections Manager",
          "inspections.activities": "Activity Inspection",
          "inspections.equipment": "Equipment Inspection",
          "inspections.receiving": "Receiving Inspection",
          "internal-audit": "Audits",
          "inventory": "Batch",
          "location": "Location",
          "location-manager": "Location Manager",
          "location-type": "Location Type",
          "ncrs": "NCR",
          "ncr-type": "NCR Type",
          "oosforms": "Out Of Specification",
          "orders": "Order",
          "part-type": "Part Type",
          "part.activity": "Activity Manager",
          "part": "Part",
          "parts": "Product",
          "planning": "Planning",
          "planning-reports": "Planning Report",
          "product-manager": "Product",
          "products": "Product",
          "quality": "Quality",
          "receiving-inspections": "RI",
          "reports": "Quality Event",
          "report-templates": "Report Template",
          "reporting": "Reporting",
          "risk-report": "Risk Report",
          "risk-report.haccp": "Risk Report",
          "shipping-receiving": "Shipping and Receiving ",
          "shipments": "Shipment",
          "suppliers": "Supplier",
          "supply-chains": "Supply Chain",
          "task-manager": "Task Manager",
          "testing": "Test",
          "training-programs": "Training Program",
          "training-schedules": "Training Activity",
          "training-type": "Training Type",
          "users": "User",
          "users-teams": "User and Team"
        }
      };

      if (this.get('updateTablesorterEachRender')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$(".data-table").tablesorter();
        });
      }

      var prefix = '';
      var routeName = this.router.currentPath;

      if (routeName.indexOf('.archived') !== -1) {
        prefix = this.get('intl').t('Archived') + ' ';
      }

      if (routeName.indexOf('.completed') !== -1) {
        prefix = this.get('intl').t('Completed') + ' ';
      }

      var moduleSingularFromDb = this.get('currentModule.singular');
      var modulePluralFromDb = this.get('currentModule.plural');
      var slug = this.get('currentModule.slug');
      var translationSingular = this.slugs.singular[slug];
      var translationPlural = this.slugs.plural[slug];

      if (moduleSingularFromDb == translationSingular) {
        if (Ember.isEmpty(this.get('singular'))) {
          this.set('singular', prefix + this.get('intl').t("modules.singular.".concat(this.get('currentModule.slug'))));
        }
      } else {
        if (Ember.isEmpty(this.get('singular'))) {
          this.set('singular', prefix + this.get('currentModule.singular'));
        }
      }

      if (modulePluralFromDb == translationPlural) {
        if (Ember.isEmpty(this.get('plural'))) {
          this.set('plural', prefix + this.get('intl').t("modules.plural.".concat(this.get('currentModule.slug'))));
        }
      } else {
        if (Ember.isEmpty(this.get('plural'))) {
          this.set('plural', prefix + this.get('currentModule.plural'));
        }
      }

      if (!modulePluralFromDb) {
        this.set('plural', prefix + this.get('intl').t("modules.plural.".concat(this.get('currentModule.slug'))));
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('updateTablesorterEachRender')) {
        this.$(".data-table").trigger("updateAll");
      }
    },
    onFilterStringChange: Ember.observer('filterString', function () {
      if (this.get('modelName') && this.get('model').get('length') >= _environment.default.APP.pagination) {
        Ember.run.debounce(this, this.applyFilter, 500);
      } else {
        this.notifyPropertyChange('filteredModel');
      }
    }),
    onFilterDropdownValue: Ember.observer('filterDropdownValue', function () {
      this.sendAction('changeFilterDropdownValue', this.get('filterDropdownValue'));
    }),
    applyFilter: function applyFilter() {
      this.set('filter', this.get('filterString'));
    },
    onFilterChange: Ember.observer('filter', function () {
      var _this = this;

      var filter = this.get('filter');

      if (Ember.isEmpty(filter)) {
        this.notifyPropertyChange('filteredModel');
        return;
      }

      this.get('store').query(this.get('modelName'), {
        keyword: filter
      }).then(function (items) {
        _this.set('items', items);

        _this.notifyPropertyChange('filteredModel');
      });
    }),
    getFilteredModel: function getFilteredModel() {
      var _this2 = this;

      var model = this.get('model'),
          filter = this.get('filterString'),
          filterFields = this.get('filterFields'),
          filterDropdownValue = this.get('filterDropdownValue'),
          filterDropdownField = this.get('filterDropdownField'),
          filterChecked = this.get('filterChecked');

      if (Ember.isEmpty(filterDropdownValue) && Ember.isEmpty(filter) && Ember.isEmpty(filterChecked) || Ember.isEmpty(model)) {
        return model;
      }

      if (this.get('filter') === this.get('filterString') && !Ember.isEmpty(filter)) {
        if (!Ember.isEmpty(filterDropdownValue) && !Ember.isEmpty(this.get('items'))) {
          return this.get('items').filter(function (item) {
            var dropdownFilter,
                itemValue = item.get(filterDropdownField);

            if (typeof itemValue === 'string') {
              dropdownFilter = filterDropdownValue === itemValue;
            } else {
              dropdownFilter = filterDropdownValue.get('id') === itemValue.get('id');
            }

            if (dropdownFilter) {
              return item;
            }
          });
        }

        return this.get('items');
      }

      return model.filter(function (item) {
        if (filter === undefined && filterDropdownValue === undefined && filterChecked === undefined) {
          return item;
        }

        if (filterFields === undefined) filterFields = ['name'];
        var stringFilter = false;

        if (filter !== undefined) {
          for (var i = 0, len = filterFields.length; i < len; i++) {
            var value = item.get(filterFields[i]);

            if (!Ember.isEmpty(value)) {
              value = value.toString();

              if (value.indexOf(filter) !== -1 || value.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                stringFilter = true;
              }
            }
          }
        } else stringFilter = true;

        var checkFilter = false;

        if (!Ember.isEmpty(filterChecked) && filterChecked === true) {
          if (_this2.attrs.filterCheckboxHandler(item)) {
            checkFilter = true;
          }
        } else checkFilter = true;

        if (!Ember.isEmpty(filterDropdownValue)) {
          var dropdownFilter,
              itemValue = item.get(filterDropdownField);

          if (typeof itemValue === 'string') {
            dropdownFilter = filterDropdownValue === itemValue;
          } else {
            dropdownFilter = itemValue && filterDropdownValue.get('id') === itemValue.get('id');
          }

          if (stringFilter && dropdownFilter && checkFilter) {
            return item;
          }
        } else {
          if (stringFilter && checkFilter) {
            return item;
          }
        }
      });
    },
    actions: {
      createDocumentIfNotAdminMessage: function createDocumentIfNotAdminMessage() {
        var fm = this.get('flashMessages');
        fm.danger("Only admin level users can create a new document.");
      }
    }
  });

  _exports.default = _default;
});